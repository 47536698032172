import React from "react"
import PropTypes from "prop-types"

import AffiliateLink from "./affiliate-link"

const ButtonWebsite = ({ href, affiliate }) => (
  <AffiliateLink href={href} affiliate={affiliate}>
    {href}
  </AffiliateLink>
)

ButtonWebsite.propTypes = {
  href: PropTypes.string,
  affiliate: PropTypes.string,
}

export default ButtonWebsite
