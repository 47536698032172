import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Parser from "html-react-parser"
import StripTags from "striptags"
import { Row, Col } from "react-bootstrap"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from "../components/container"
import ExploreMoreApps from "../components/section-explore-more-apps"
import Newsletter from "../components/section-newsletter"
import BackgroundInterior from "../components/background/interior"
import ButtonWhiteiOS from "../components/button/white-ios"
import ButtonWhiteAndroid from "../components/button/white-android"
import ButtonWebsite from "../components/button/website"

export default ({ data }) => {
  const {
    publisherLogo,
    appsJson: {
      id,
      title,
      description,
      quick_summary,
      how_it_works,
      star_rating,
      year_founded,
      methods_to_earn,
      difficulty_to_earn,
      reward_type,
      unique_features,
      cost,
      platform,
      link_ios,
      link_android,
      link_website,
      link_affiliate_app,
      link_affiliate_web,
      app_image,
      alt,
      application_category,
      price,
    },
  } = data

  const parsedTitle = Parser(title)
  const parsedDescription = Parser(quick_summary)

  const textTitle =
    typeof parsedTitle === "object"
      ? parsedTitle
          .map((m) => (m.props && m.props.children ? m.props.children : m))
          .join("")
      : parsedTitle

  const textDescription =
    typeof parsedDescription === "object"
      ? parsedDescription
          .map((m) => (m.props && m.props.children ? m.props.children : m))
          .join("")
      : parsedDescription

  // https://developers.google.com/search/docs/data-types/software-app
  const schema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: `${StripTags(textTitle)}`,
    description: `${StripTags(textDescription)}`,
    operatingSystem: `${platform.join(", ")}`,
    applicationCategory: `${application_category}`,
    image: `${app_image.childImageSharp.fixed.src}`,
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: `${star_rating}`,
      },
      author: {
        "@type": "Organization",
        name: "MicroIncome.com",
      },
      reviewBody: `${difficulty_to_earn}`,
      publisher: {
        "@type": "Organization",
        name: "MicroIncome.com",
        url: "https://www.microincome.com/",
        logo: {
          "@type": "ImageObject",
          url: `${publisherLogo.childImageSharp.fixed.src}`,
        },
      },
    },
    offers: {
      "@type": "Offer",
      price: `${price}`,
      priceCurrency: "USD",
    },
  }

  return (
    <Layout pageInfo={{ pageName: "app" }}>
      <SEO
        title={`${StripTags(textTitle)} app review`}
        description={StripTags(textDescription)}
        schema={schema}
      />
      <BackgroundInterior>
        <Container className="template-app-page">
          <h1 className="text-center">{parsedTitle}</h1>
          <Row>
            <Col md className="app-image-buttons">
              <Img
                className="app-image"
                fluid={app_image.childImageSharp.fluid}
                alt={alt}
              />
              {(platform.indexOf("iOS") !== -1 ||
                platform.indexOf("Android") !== -1) && (
                <Row className="app-buttons">
                  {platform.indexOf("iOS") !== -1 && (
                    <Col>
                      <ButtonWhiteiOS
                        href={link_ios}
                        affiliate={link_affiliate_app}
                        coming={link_website}
                      />
                    </Col>
                  )}
                  {platform.indexOf("Android") !== -1 && (
                    <Col>
                      <ButtonWhiteAndroid
                        href={link_android}
                        affiliate={link_affiliate_app}
                        coming={link_website}
                      />
                    </Col>
                  )}
                </Row>
              )}
              {link_website && (
                <p className="app-website">
                  <strong>
                    Website: {` `}
                    <ButtonWebsite
                      href={link_website}
                      affiliate={link_affiliate_web}
                    />
                  </strong>
                </p>
              )}
            </Col>
            <Col md className="app-content">
              {description && (
                <p>
                  <strong>{Parser(description)}</strong>
                </p>
              )}
              {quick_summary && (
                <p>
                  <strong>Quick Summary:</strong> {Parser(quick_summary)}
                </p>
              )}
              {how_it_works && (
                <p>
                  <strong>How It Works:</strong> {Parser(how_it_works)}
                </p>
              )}
              {star_rating && (
                <p>
                  <strong>Star Rating:</strong>
                  {` `}
                  {Array.from({ length: star_rating }, (x, i) => (
                    <span key={i} role="img" aria-label="star">
                      &#11088;
                    </span>
                  ))}
                </p>
              )}
              {year_founded && (
                <p>
                  <strong>Year Founded:</strong> {Parser(year_founded)}
                </p>
              )}
              {methods_to_earn && (
                <p>
                  <strong>Methods To Earn:</strong> {Parser(methods_to_earn)}
                </p>
              )}
              {difficulty_to_earn && (
                <p>
                  <strong>Difficulty To Earn:</strong>{" "}
                  {Parser(difficulty_to_earn)}
                </p>
              )}
              {reward_type && (
                <p>
                  <strong>Reward Type:</strong> {Parser(reward_type)}
                </p>
              )}
              {unique_features && (
                <p>
                  <strong>Unique Features:</strong> {Parser(unique_features)}
                </p>
              )}
              {cost && (
                <p>
                  <strong>Cost:</strong> {Parser(cost)}
                </p>
              )}
              {platform && (
                <p>
                  <strong>Platform(s):</strong> {platform.join(", ")}
                </p>
              )}
            </Col>
          </Row>
          <hr />
        </Container>
        <ExploreMoreApps skip={id} />
        <Newsletter />
      </BackgroundInterior>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    publisherLogo: file(relativePath: { eq: "Icon@3x.png" }) {
      childImageSharp {
        fixed(width: 336) {
          src
        }
      }
    }
    appsJson(id: { eq: $id }) {
      id
      title
      description
      quick_summary
      how_it_works
      star_rating
      year_founded
      methods_to_earn
      difficulty_to_earn
      reward_type
      unique_features
      cost
      platform
      link_ios
      link_android
      link_website
      link_affiliate_app
      link_affiliate_web
      app_image {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(width: 400) {
            src
          }
        }
      }
      alt
      application_category
      price
    }
  }
`
