import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, CardDeck } from "react-bootstrap"

import CardApp from "../card-app"
import ButtonWhiteOutline from "../button/white-outline"

const ExploreMoreApps = ({ skip }) => {
  const { allAppsJson } = useStaticQuery(graphql`
    query {
      allAppsJson(limit: 4, sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            path
            title
            description
            featured_image {
              childImageSharp {
                fluid(maxWidth: 320, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            link_ios
            link_android
            link_website
            link_affiliate_app
            link_affiliate_web
            platform
          }
        }
      }
    }
  `)

  const recommended = []
  allAppsJson.edges.forEach((app) => {
    if (app.node.id !== skip && recommended.length < 3) recommended.push(app)
  })

  return (
    <section className="section-explore-more-apps">
      <Container>
        <h2>
          <span>Explore</span> More Apps!
        </h2>
        <CardDeck className="card-app-deck">
          {recommended.map((app) => {
            const {
              id,
              path,
              title,
              description,
              featured_image,
              alt,
              link_ios,
              link_android,
              link_affiliate_app,
              link_affiliate_web,
              link_website,
              platform,
            } = app.node
            return (
              <CardApp
                key={id}
                title={title}
                description={description}
                image={featured_image.childImageSharp.fluid}
                alt={alt}
                path={path}
                ios={link_ios}
                android={link_android}
                website={link_website}
                affiliate_app={link_affiliate_app}
                affiliate_web={link_affiliate_web}
                platform={platform}
              />
            )
          })}
        </CardDeck>
        <ButtonWhiteOutline as={Link} to="/top-15-apps">
          See More Apps
        </ButtonWhiteOutline>
      </Container>
    </section>
  )
}

export default ExploreMoreApps
