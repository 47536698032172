import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AffiliateLink from "./affiliate-link"

const ButtonWhiteAndroid = ({ href, affiliate, coming }) => {
  const { androidDownload, androidComingSoon } = useStaticQuery(graphql`
    query {
      androidDownload: file(
        relativePath: { eq: "White_Download_Googleplay@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      androidComingSoon: file(
        relativePath: { eq: "White_Preorder_Googleplay@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return href ? (
    <AffiliateLink
      href={href}
      affiliate={affiliate}
      className="link-app-button android"
    >
      <Img
        fixed={androidDownload.childImageSharp.fixed}
        alt="Get it on Google Play"
      />
    </AffiliateLink>
  ) : coming ? (
    <AffiliateLink
      href={coming}
      affiliate={affiliate}
      className="link-app-button link-app-coming-soon android"
    >
      <Img
        fixed={androidComingSoon.childImageSharp.fixed}
        alt="Coming Soon to Google Play"
      />
    </AffiliateLink>
  ) : (
    <span className="link-app-button link-app-coming-soon android">
      <Img
        fixed={androidComingSoon.childImageSharp.fixed}
        alt="Coming Soon to Google Play"
      />
    </span>
  )
}

ButtonWhiteAndroid.propTypes = {
  href: PropTypes.string,
  affiliate: PropTypes.string,
  coming: PropTypes.string,
}

export default ButtonWhiteAndroid
