import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AffiliateLink from "./affiliate-link"

const ButtonWhiteiOS = ({ href, affiliate, coming }) => {
  const { iosDownload, iosComingSoon } = useStaticQuery(graphql`
    query {
      iosDownload: file(
        relativePath: { eq: "White_Download_Appstore@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iosComingSoon: file(
        relativePath: { eq: "White_Preorder_Appstore@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return href ? (
    <AffiliateLink
      href={href}
      affiliate={affiliate}
      className="link-app-button ios"
    >
      <Img
        fixed={iosDownload.childImageSharp.fixed}
        alt="Download on the App Store"
      />
    </AffiliateLink>
  ) : coming ? (
    <AffiliateLink
      href={coming}
      affiliate={affiliate}
      className="link-app-button link-app-coming-soon ios"
    >
      <Img
        fixed={iosComingSoon.childImageSharp.fixed}
        alt="Coming Soon on the App Store"
      />
    </AffiliateLink>
  ) : (
    <span className="link-app-button link-app-coming-soon ios">
      <Img
        fixed={iosComingSoon.childImageSharp.fixed}
        alt="Coming Soon on the App Store"
      />
    </span>
  )
}

ButtonWhiteiOS.propTypes = {
  href: PropTypes.string,
  affiliate: PropTypes.string,
  coming: PropTypes.string,
}

export default ButtonWhiteiOS
